import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Carousel({ pozo, gallery, title }) {

  if (pozo) {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <>
        <Slider {...settings}>
          {
            gallery && gallery.map((item, index) => (
              <div key={index}>
                <div className="content-slide">
                  <div className="bg-black" style={{ backgroundImage: `url('${item}')` }}>
                  </div>
                  <div className="bg-overlay">
                  </div>
                  <img className="img-fliud" src={`${item}`} alt={`${title}`} title={`${title}`} />
                </div>
              </div>
            ))
          }
        </Slider>
      </>
    )
  } else {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <>
        <Slider {...settings}>
          {
            gallery && gallery.map((item, index) => (
              <div key={index}>
                <div className="content-slide">
                  <img className="img-fluid" src={`${item}`} alt={`${title}`} title={`${title}`} />
                  </div>
              </div>
            ))
          }
        </Slider>
      </>
    )
  }

}

export default Carousel