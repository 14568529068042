import React from 'react';

function Footer( { info } ) {
  return (
    <>
      <footer className="footer">
        <div className="footer__top">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-6">
                <img className="img-fluid footer__top--logo" src="/images/logo-dorika-futuro-en-desarrollo-footer.svg" alt="Dórika" />
                <ul className="footer__top--info">
                  {
                    info.address && (
                      <li>
                        <a href={`https://www.google.com/maps/place/${info.address}, Resistencia Chaco`} target="_blank" rel="noreferrer">
                          <span>
                            <img className="img-fluid" src="/images/dorika-icono-mapa.svg" alt={info.address} title={info.address}/>
                          </span>{info.address}</a>
                      </li>
                    )
                  }
                  <li>
                    <a href={`https://api.whatsapp.com/send?phone=${info.phone.replace(/\s/g,'')}`} target="_blank" rel="noreferrer">
                      <span>
                        <img className="img-fluid" src="/images/dorika-icono-telefono.svg" alt={info.phone} title={info.phone} />
                      </span>{info.phone}</a>
                  </li>
                  <li>
                    <a href={`mailto:${info.email}`} target="_blank" rel="noreferrer">
                      <span>
                        <img className="img-fluid" src="/images/dorika-icono-email.svg" alt={info.email} title={info.email} />
                      </span>{info.email}</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul className="footer__top--rrss d-flex justify-content-lg-end">
                  <li>
                    <a href={info.facebook} target="_blank" rel="noreferrer">
                      <img className="img-fluid" src="/images/dorika-icono-facebook.svg" alt="Facebook" title="Facebook" />

                    </a>
                  </li>
                  <li>
                    <a href={info.instagram} target="_blank" rel="noreferrer">
                      <img className="img-fluid" src="/images/dorika-icono-instagram.svg" alt="Instagram" title="Instagram" /></a>
                  </li>
                  <li>
                    <a href={`https://wa.me/549${info.whatsapp}`} target="_blank" rel="noreferrer">
                      <img className="img-fluid" src="/images/dorika-icono-whatsapp.svg" alt="Whatsapp" title="Whatsapp" /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer__buttom text-center">
          <a href="https://circulo.digital/" target="_blank" rel="noreferrer">
            <img className="img-fliud" src="/images/creado-por-circulo-agencia.svg" alt="Circulo Agencia" title="Creado por circulo agencia" /></a>
        </div>
      </footer>
    </>
  )
}

export default Footer