import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Footer from "./components/layout/Footer"
import Navbar from "./components/layout/Navbar"
import ProyectsList from "./components/shared/ProyectsList"
import AOS from 'aos'
import 'aos/dist/aos.css'
import Form from "./components/shared/Form"

function App() {
  AOS.init();

  const [ home, setHome ] = useState(null)

  useEffect(() => {
    const getData = async () => {
      try {
        const dataHome = await axios.get('https://somosdorika.com.ar/admin/wp-json/api/v1/home')
        setHome(dataHome.data[0])
      } catch (error) {
        console.error(error)
      }
    }
    getData()
  }, [])

  return (
    <>
      <Navbar />
      {
        home && (
        <header>
          <img className="banner-image" src={home.banner.image} alt={home.banner.text} title={home.banner.text} />
          <div className="banner-text d-flex align-items-center">
            <div className="container">
              <div className="row justify-content-center justify-content-lg-start">
                <div className="col-md-6 text-center text-lg-left">
                  <h1 className="f-medium">
                    <span data-aos="fade-right" data-aos-duration="1000">
                      {home.banner.text.line_1} </span><br />
                    <span data-aos="fade-right" data-aos-duration="2000">{home.banner.text.line_2}
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        )
      }

      <section className="nosotros" id="nosotros">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="heading--title">Somos Dórika</h2>
              <p className="heading--subtitle f-light">Integramos una empresa de desarrollo urbano e inmobiliario, diseño y construcción de obras edilicias destinadas a familias, privados y entes públicos del noreste argentino. Trabajamos en sinergia junto a grandes empresas desde Resistencia, Chaco. Nuestro equipo tiene la experiencia y calidad para brindar un servicio especializado adaptado a las necesidades de cada cliente. Acompañamos y concretamos los sueños de quienes quieren empezar una nueva etapa, ya que todos nuestros proyectos han sido presentados y terminados exitosamente. <br />Crecemos para ser líderes en el mercado inmobiliario, proporcionando los cimientos para el crecimiento regional con compromiso y responsabilidad tanto ambiental como social. <strong>Somos Dórika, el futuro en desarrollo</strong>.</p>
            </div>
            <div className="divider large">
            </div>
          </div>
        </div>
      </section>

      <section className="proyectos" id="proyectos">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="heading--title">Proyectos en Pozo</h2>
            </div>
          </div>
          <ProyectsList preVenta={true} />
        </div>
      </section>

      <section className="proyectos-realizados pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="heading--title">
                Proyectos realizados
              </h2>
            </div>
          </div>
          <div className="row">
            <ProyectsList preVenta={false} />
          </div>
        </div>
      </section>

      <section className="contacto" id="contacto">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="heading--title">Dejanos un mensaje</h2>
              <p className="heading--subtitle f-light">Si estas pensando en tener una rentabilidad o comprar un inmueble escribinos y un asesor te contacta.</p>
            </div>
            <div className="col-lg-12">
              <Form />
            </div>
          </div>
        </div>
        <div className="contacto__map">
          {
            home && home.footer.address && (
              <a href={`https://www.google.com/maps/place/${home.footer.address}, Resistencia Chaco`} target="_blank" rel="noreferrer">
                <img className="" src={home.footer.map} alt={home.footer.address} title={home.footer.address} />
              </a>
            )
          }
        </div>
      </section>

      {
        home && (
          <Footer info={home.footer} />
        )
      }
    </>
  );
}

export default App;
