import React, { useState } from 'react'

function Navbar() {

  const [ sticky, setSticky ] = useState(false)
  const [ icon, setIcon ] = useState('icon-menu')
  const [ item, setItem ] = useState('')

  window.onscroll = () => {
    if (document.documentElement.scrollTop > 100) {
      setSticky(true)
    } else {
      setSticky(false)
    }
  }

  const openMenu = () => {
    if (document.getElementById("navbar-collapse").classList.contains('active')) {
      setIcon('icon-menu');
    } else {
      setIcon('icon-menu-close');
    }
		document.getElementById("navbar-collapse").classList.toggle("active");
	}

  const handleScroll = (element) => {
    setItem(element)
    let topElem = document.getElementById(element)
    let top = topElem.offsetTop
    window.scrollTo({ top: top - 25, behavior: 'smooth' })
    if (document.getElementById("navbar-collapse").classList.contains('active')) {
      openMenu()
    }
  }

  return (
    <>
      <nav className={sticky ? 'navbar active' : 'navbar' } id="navbar">
        <div className="container w-100">
          <div className="row align-items-center">
            <div className="col-6 col-lg-3">
              <span className="navbar-brand" onClick={ () => handleScroll('navbar')}>
                <img src="/images/logo-dorika.svg" className="img-fluid d-block" alt="DÓRIKA - logo" title="DÓRIKA - logo" />
              </span>
            </div>
            <div className="col-6 col-lg-9 d-flex justify-content-end position-relative">
              <button className="navbar-toggler" type="button" onClick={openMenu}>
                <span className="navbar-toggler-icon">
                  <img className='img-fluid' src={`../images/${icon}.svg`} alt="Abrir menu"/>
                </span>
              </button>
              <div className="navbar-collapse justify-content-center justify-content-md-end" id="navbar-collapse">
                <ul className="navbar-nav flex-md-row">
                  <li className={`${(item === 'nosotros' ? 'active' : '')} nav-item f-medium`} onClick={ () => handleScroll('nosotros')}>NOSOTROS</li>
                  <li className={`${(item === 'proyectos' ? 'active' : '')} nav-item f-medium`} onClick={ () => handleScroll('proyectos')}>PROYECTOS</li>
                  <li className={`${(item === 'contacto' ? 'active' : '')} nav-item f-medium`} onClick={ () => handleScroll('contacto')}>CONTACTO</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar