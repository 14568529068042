import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios'

function Form() {

  const [sent, setSent] = useState(false)
  const [notSent, setNotSent] = useState(false)
  const [sending, setSending] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = data => {
    setSending(true)
    const form = new FormData()
    form.append('name', data.name)
    form.append('email', data.email)
    form.append('phone', data.phone)
    form.append('message', data.message)

    axios
      .post(`https://somosdorika.com.ar/dejando-mensaje.php`, form)
      .then((res) => {
        const message = res.data
        setSending(false)
        if (message === 'success') {
          setSent(true)
          document.querySelector('#form-contact').reset()
        } else {
          setNotSent(true)
        }
      })
      .catch((error) => {
        console.log('catch ', error)
        setNotSent(true)
      })
  }

  return (
    <form id="form-contact" className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="form-group col-lg-4">
          <label className="label f-light" id="labelName">Nombre *</label>
          <input
            {...register('name', { required: true })}
            name="name"
            type="text"
            className={errors.name ? 'form-control is-invalid' : 'form-control'}
          />
          {errors.name && <div className='invalid-feedback'>Tenes que ingresar un Nombre.</div>}
        </div>
        <div className="form-group col-lg-4">
          <label className="label f-light" id="labelEmail">E-mail *</label>
          <input
            {...register('email', { required: true }, { pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i } })}
            name="email"
            type="email"
            className={errors.email ? 'form-control is-invalid' : 'form-control'}
          />
          {errors.email && <div className='invalid-feedback'>Tenes que ingresar un E-mial válido.</div>}
        </div>
        <div className="form-group col-lg-4">
          <label className="label f-light" id="labelPhone">Teléfono *</label>
          <input
            {...register('phone', { required: true })}
            name="phone"
            type="text"
            className={errors.phone ? 'form-control is-invalid' : 'form-control'}
          />
          {errors.phone && <div className='invalid-feedback'>Tenes que ingresar un número de Teléfono.</div>}
        </div>
        <div className="form-group col-lg-12">
          <label className="label f-light" id="labelMessage">Mensaje</label>
          <textarea
            {...register('message', { required: true })}
            className="form-control"
            rows="7"
            name="message"
          ></textarea>
        </div>
        <div className="orm-group col-lg-12 my-0">
        </div>
        <div className="form-group col-lg-12 text-center mb-0">
        {sent && (
          <div className='alert alert-success w-100' role='alert'>
            <strong>¡Gracias!</strong> Tu mensaje se envío correctamente
          </div>
        )}
        {notSent && (
          <div className='alert alert-danger w-100' role='alert'>
            <strong>¡Ups!</strong> Algo salió mal, intenta de nuevo.
          </div>
        )}
          <button type="submit" className="btn btn-primary f-medium">
            {sending
              ?
              <>
                <div className='spinner-border text-light' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
                <span>Enviando</span>
              </>
              :
              <span>Enviar</span>
            }
          </button>
        </div>
      </div>
    </form>
  );
}

export default Form
