import React, { useState, useEffect, Fragment } from 'react'
import axios from 'axios'
import data from '../../data/data.json'
import Carousel from './Carousel'
import Modal from './Modal'

function ProyectsList( { preVenta }) {

  const [ allProjects, setAllProjects ] = useState([])

  useEffect(() => {
    const getData = async () => {
      try {
        const projects = await axios.get('https://somosdorika.com.ar/admin/wp-json/api/v1/proyectos')
        setAllProjects(projects.data)
      } catch (error) {
        console.error(error)
        const projects = data
        setAllProjects(projects)
      }
    }
    getData()
  }, [])

  if ( preVenta ) {
    
    const pozo = allProjects.filter(project => project.state === 'Pre-Venta')

    return (
      <>
        {
          pozo.length > 0 && pozo.map((project, index) => (
            (index % 2 === 0)
            ?
            <div className="row project project--left" key={project.id}>
              <div className="col-lg-4">
                <div className="project__logo text-center">
                  <img src={project.logo} className="img-fluid" alt={`${project.title}`} title={`${project.title}`} />
                </div>
                <div className="divider light">
                </div>
                <div className="project__text">
                  <p className="project__text--address f-light">
                    <span className="mr-2">
                      <img className="" src="../images/dorika-maps.svg" alt="icono de mapa" title={`${project.title}`} />
                    </span>
                    {project.address}
                  </p>
                  <div className="project__text--description f-light" dangerouslySetInnerHTML={{ __html: `${project.text}` }}></div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="tag">Pre-Venta</div>
                <Carousel gallery={project.gallery} title={project.title} pozo={true}/>
              </div>
            </div>
            :
            <div className="row project project--right flex-lg-row-reverse" key={project.id}>
              <div className="col-lg-4">
                <div className="project__logo text-center">
                <img src={project.logo} className="img-fluid" alt={`${project.title}`} title={`${project.title}`} />
                </div>
                <div className="divider light">
                </div>
                <div className="project__text">
                  <p className="project__text--address f-light">
                    <span className="mr-2">
                      <img className="" src="../images/dorika-maps.svg" alt="icono de mapa" title={`${project.title}`} />
                    </span>
                    {project.address}
                  </p>
                  <div className="project__text--description f-light" dangerouslySetInnerHTML={{ __html: `${project.text}` }}></div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="tag">Pre-Venta</div>
                <Carousel gallery={project.gallery} title={project.title} pozo={true}/>
              </div>
            </div>
          ))
        }
      </>
    )
  } else {

    const realized = allProjects.filter(project => project.state === 'Realizado')
    const handleModal = (id ) => {
      document.getElementById(`popup${id}`).classList.toggle('active')
    }

    return (
      <>
        {
          realized.length > 0 && realized.map((project) => (
            <Fragment key={project.id}>
              <div className="col-lg-4 px-lg-0">
                <div className="item" onClick={ () => handleModal(project.id)}>
                  <img className="item__image img-fluid" src={`${project.cover}`} alt={`${project.title}`} title={`${project.title}`} />
                  <div className="item__name">{project.title}</div>
                </div>
              </div>
              <Modal project={project} id={project.id} handleModal={handleModal}/>
            </Fragment>
          ))
        }
      </>
    )
  }


}

export default ProyectsList