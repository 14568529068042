import React from 'react'
import Carousel from './Carousel'

function Modal( { project, id, handleModal} ) {

  const closeModal = ( id ) => {
    handleModal(id)
  }

  return (
    <>
      <div className="popup" id={`popup${id}`}>
        <div className="container">
          <div className="row popup__content">
            <div className="col-lg-7">
              <div className="button-close text-right mobile">
                <img src="/images/icon-close.svg" alt="Cerrar modal" onClick={ () => closeModal(id)}/>
              </div>
              <div className="popup__content--text mobile">
                <h4>{project.phrase}</h4>
              </div>
              <div className="divider light mobile">
              </div>
              <div className="popup__content--gallery">
                <Carousel pozo={false} gallery={project.gallery} title={project.title} />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="button-close text-right desktop">
                <img src="/images/icon-close.svg" alt="Cerrar modal" onClick={ () => closeModal(id)}/>
              </div>
              <div className="popup__content--text">
                <h4 className="desktop">{project.phrase}</h4>
                <div className="divider light desktop"></div>
                <div className="description" dangerouslySetInnerHTML={{ __html: `${project.text}` }}></div>
                <ul className="details">
                  <li><strong>Dirección</strong>: {project.address}</li>
                  <li><strong>Ciudad</strong>: {project.details.city}</li>
                  <li><strong>Estado</strong>: {project.details.state}</li>
                  <li><strong>Entregado</strong>: {project.details.year}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal